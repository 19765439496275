import DimensionBox from "../DimensionBox";
import React, { useContext } from "react";
import { Box, Checkbox, Chip, FormControlLabel, Grid, OutlinedInput } from "@mui/material";
import PropTypes from "prop-types";
import SelectField from "../field/SelectField";
import { AppContext } from "../../AppRouter";

const CommonSelector = ({ disabled, chart, limit, tail, ignoreNull, statLines, hideInsights }) => {
    const { config } = useContext(AppContext);

    function getChartSelector(param) {
        const value = param?.value;
        return (
            <SelectField
                dataCyProp="chart_selector"
                label={config.i18n.selector.chart}
                value={value || ""}
                disabled={disabled || !param}
                possibleValues={param?.options || []}
                metadata={config.i18n.chart.type}
                onChange={param?.onUpdate}
            />
        );
    }

    function getLimitSelector(param) {
        const value = param?.value;
        return (
            <SelectField
                dataCyProp="top_selector"
                label={config.i18n.selector.top}
                value={value || ""}
                disabled={disabled || !param}
                possibleValues={param?.options || []}
                onChange={param?.onUpdate}
            />
        );
    }

    function getHiddenTailSelector(param) {
        const value = param?.value || false;
        return (
            <FormControlLabel
                control={(
                    <Checkbox
                        data-cy="hideTail_checkbox"
                        checked={value}
                        disabled={disabled || !param}
                        onChange={event => param.onUpdate(event.target.checked)}
                    />
                )}
                label={config.i18n.selector.hide_tail}
            />
        );
    }

    function getIgnoreNullSelector(param) {
        const value = param?.value || false;
        return (
            <FormControlLabel
                control={(
                    <Checkbox
                        data-cy="ignoreNull_checkbox"
                        checked={value}
                        disabled={disabled || !param}
                        onChange={event => param.onUpdate(event.target.checked)}
                    />
                )}
                label={config.i18n.selector.null}
            />
        );
    }

    function getStatLinesSelector(param) {
        const value = param?.value || [];
        return (
            <SelectField
                dataCyProp="statLines_selector"
                multiple
                label={config.i18n.selector.stat_lines}
                value={value}
                disabled={disabled || !param || param.disabled}
                possibleValues={param?.options}
                metadata={config.i18n.chart.lines}
                onChange={param?.onUpdate}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={selected => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map(value => (
                            <Chip size="small" key={value} label={config.i18n.chart.lines[value] || value} />
                        ))}
                    </Box>
                )}
            />
        );
    }

    function getHideInsightsSelector(param) {
        const value = param?.value || false;
        return (
            <FormControlLabel
                control={(
                    <Checkbox
                        data-cy="hideInsights_checkbox"
                        checked={value}
                        disabled={disabled || !param}
                        onChange={event => param.onUpdate(event.target.checked)}
                    />
                )}
                label={config.i18n.selector.hide_insights}
            />
        );
    }

    return (
        <DimensionBox
            title={config.i18n.customization_bar.selectors}
            internalSx={{ px: 1 }}
            collapsible
        >
            <Grid container spacing={1} rowSpacing={1.5}>
                <Grid item xs={6}>
                    {getChartSelector(chart)}
                </Grid>
                <Grid item xs={6}>
                    {getLimitSelector(limit)}
                </Grid>
                <Grid item xs={6}>
                    {getHiddenTailSelector(tail)}
                </Grid>
                <Grid item xs={6}>
                    {getIgnoreNullSelector(ignoreNull)}
                </Grid>
                <Grid item xs={12}>
                    {getStatLinesSelector(statLines)}
                </Grid>
                <Grid item xs={12}>
                    {getHideInsightsSelector(hideInsights)}
                </Grid>
            </Grid>
        </DimensionBox>
    );
};

CommonSelector.propTypes = {
    disabled: PropTypes.bool,
    chart: PropTypes.object,
    limit: PropTypes.object,
    tail: PropTypes.object,
    ignoreNull: PropTypes.object,
    statLines: PropTypes.object,
    hideInsights: PropTypes.object,
};

export default CommonSelector;
