"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dimension = void 0;
/**
 * An enumeration.
 */
var Dimension;
(function (Dimension) {
    Dimension["DATE"] = "DATE";
    Dimension["DATE_YEAR"] = "DATE_YEAR";
    Dimension["DATE_YEAR_QUARTER"] = "DATE_YEAR_QUARTER";
    Dimension["DATE_YEAR_MONTH"] = "DATE_YEAR_MONTH";
    Dimension["FISCAL_YEAR"] = "FISCAL_YEAR";
    Dimension["ORG_UNIT"] = "ORG_UNIT";
    Dimension["COMPANY"] = "COMPANY";
    Dimension["BUSINESS_UNIT"] = "BUSINESS_UNIT";
    Dimension["DEPARTMENT"] = "DEPARTMENT";
    Dimension["PROJECT"] = "PROJECT";
    Dimension["SITE"] = "SITE";
    Dimension["COUNTRY"] = "COUNTRY";
    Dimension["PROCESS"] = "PROCESS";
    Dimension["UNIT"] = "UNIT";
    Dimension["CATEGORY"] = "CATEGORY";
    Dimension["CATEGORY_L1"] = "CATEGORY_L1";
    Dimension["CATEGORY_L2"] = "CATEGORY_L2";
    Dimension["CATEGORY_L3"] = "CATEGORY_L3";
    Dimension["CATEGORY_L4"] = "CATEGORY_L4";
    Dimension["CATEGORY_L5"] = "CATEGORY_L5";
    Dimension["PURCHASE_CATEGORY"] = "PURCHASE_CATEGORY";
    Dimension["PURCHASE_CATEGORY_L1"] = "PURCHASE_CATEGORY_L1";
    Dimension["PURCHASE_CATEGORY_L2"] = "PURCHASE_CATEGORY_L2";
    Dimension["PURCHASE_CATEGORY_L3"] = "PURCHASE_CATEGORY_L3";
    Dimension["PURCHASE_CATEGORY_L4"] = "PURCHASE_CATEGORY_L4";
    Dimension["PURCHASE_CATEGORY_L5"] = "PURCHASE_CATEGORY_L5";
    Dimension["SPEND_GROUP"] = "SPEND_GROUP";
    Dimension["SALES_CATEGORY"] = "SALES_CATEGORY";
    Dimension["SALES_CATEGORY_L1"] = "SALES_CATEGORY_L1";
    Dimension["SALES_CATEGORY_L2"] = "SALES_CATEGORY_L2";
    Dimension["SALES_CATEGORY_L3"] = "SALES_CATEGORY_L3";
    Dimension["SALES_CATEGORY_L4"] = "SALES_CATEGORY_L4";
    Dimension["SALES_CATEGORY_L5"] = "SALES_CATEGORY_L5";
    Dimension["LOCATION"] = "LOCATION";
    Dimension["LOCATION_L1"] = "LOCATION_L1";
    Dimension["LOCATION_L2"] = "LOCATION_L2";
    Dimension["LOCATION_L3"] = "LOCATION_L3";
    Dimension["LOCATION_L4"] = "LOCATION_L4";
    Dimension["LOCATION_L5"] = "LOCATION_L5";
    Dimension["PURCHASE_LOCATION"] = "PURCHASE_LOCATION";
    Dimension["PURCHASE_LOCATION_L1"] = "PURCHASE_LOCATION_L1";
    Dimension["PURCHASE_LOCATION_L2"] = "PURCHASE_LOCATION_L2";
    Dimension["PURCHASE_LOCATION_L3"] = "PURCHASE_LOCATION_L3";
    Dimension["PURCHASE_LOCATION_L4"] = "PURCHASE_LOCATION_L4";
    Dimension["PURCHASE_LOCATION_L5"] = "PURCHASE_LOCATION_L5";
    Dimension["SALES_LOCATION"] = "SALES_LOCATION";
    Dimension["SALES_LOCATION_L1"] = "SALES_LOCATION_L1";
    Dimension["SALES_LOCATION_L2"] = "SALES_LOCATION_L2";
    Dimension["SALES_LOCATION_L3"] = "SALES_LOCATION_L3";
    Dimension["SALES_LOCATION_L4"] = "SALES_LOCATION_L4";
    Dimension["SALES_LOCATION_L5"] = "SALES_LOCATION_L5";
    Dimension["DOCUMENT"] = "DOCUMENT";
    Dimension["MATERIAL"] = "MATERIAL";
    Dimension["MATERIAL_GROUP"] = "MATERIAL_GROUP";
    Dimension["PRODUCT"] = "PRODUCT";
    Dimension["PRODUCT_GROUP"] = "PRODUCT_GROUP";
    Dimension["ACCOUNT"] = "ACCOUNT";
    Dimension["ACCOUNT_GROUP"] = "ACCOUNT_GROUP";
    Dimension["ACCOUNT_GROUP_L1"] = "ACCOUNT_GROUP_L1";
    Dimension["ACCOUNT_GROUP_L2"] = "ACCOUNT_GROUP_L2";
    Dimension["ACCOUNT_GROUP_L3"] = "ACCOUNT_GROUP_L3";
    Dimension["ACCOUNT_GROUP_L4"] = "ACCOUNT_GROUP_L4";
    Dimension["ACCOUNT_GROUP_L5"] = "ACCOUNT_GROUP_L5";
    Dimension["CLIENT"] = "CLIENT";
    Dimension["CLIENT_COUNTRY"] = "CLIENT_COUNTRY";
    Dimension["CLIENT_SEGMENT"] = "CLIENT_SEGMENT";
    Dimension["VENDOR"] = "VENDOR";
    Dimension["VENDOR_TYPE"] = "VENDOR_TYPE";
    Dimension["VENDOR_SEGMENT"] = "VENDOR_SEGMENT";
    Dimension["VENDOR_CONTRACT"] = "VENDOR_CONTRACT";
    Dimension["VENDOR_CONTRACT_ITEM"] = "VENDOR_CONTRACT_ITEM";
    Dimension["VENDOR_COUNTRY"] = "VENDOR_COUNTRY";
    Dimension["OWNER"] = "OWNER";
    Dimension["COST_CENTER"] = "COST_CENTER";
    Dimension["DELIVERY_LOCATION"] = "DELIVERY_LOCATION";
    Dimension["DATA_SOURCE"] = "DATA_SOURCE";
    Dimension["TAG"] = "TAG";
    Dimension["ACCOUNTING_TREATMENT"] = "ACCOUNTING_TREATMENT";
    Dimension["DISCRETIONARY_COST"] = "DISCRETIONARY_COST";
    Dimension["FIXED_COST"] = "FIXED_COST";
    Dimension["DIRECT_COST"] = "DIRECT_COST";
    Dimension["DEMAND_FRAGMENTATION"] = "DEMAND_FRAGMENTATION";
    Dimension["SWITCHING_COST"] = "SWITCHING_COST";
    Dimension["SUPPLY_CONTROL"] = "SUPPLY_CONTROL";
    Dimension["INTRA_GROUP"] = "INTRA_GROUP";
    Dimension["PURCHASE_ORDER_TYPE"] = "PURCHASE_ORDER_TYPE";
    Dimension["INVOICE_TYPE"] = "INVOICE_TYPE";
    Dimension["FOREX"] = "FOREX";
    Dimension["FOREX_CONTRACT"] = "FOREX_CONTRACT";
    Dimension["REGION"] = "REGION";
    Dimension["GEOGRAPHY"] = "GEOGRAPHY";
})(Dimension || (exports.Dimension = Dimension = {}));
