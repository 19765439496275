"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountingTreatment = void 0;
/**
 * An enumeration.
 */
var AccountingTreatment;
(function (AccountingTreatment) {
    AccountingTreatment["COST"] = "COST";
    AccountingTreatment["EXPENSES"] = "EXPENSES";
    AccountingTreatment["GROSS_PROFIT"] = "GROSS_PROFIT";
    AccountingTreatment["EBITDA"] = "EBITDA";
    AccountingTreatment["ADJUSTED_EBITDA"] = "ADJUSTED_EBITDA";
    AccountingTreatment["EBIT"] = "EBIT";
    AccountingTreatment["EBT"] = "EBT";
    AccountingTreatment["NET_INCOME"] = "NET_INCOME";
    AccountingTreatment["NET_REVENUE"] = "NET_REVENUE";
    AccountingTreatment["NET_SALES"] = "NET_SALES";
    AccountingTreatment["GROSS_SALES"] = "GROSS_SALES";
    AccountingTreatment["DISCOUNTS"] = "DISCOUNTS";
    AccountingTreatment["REFUNDS"] = "REFUNDS";
    AccountingTreatment["OTHER_SALES"] = "OTHER_SALES";
    AccountingTreatment["INTERNAL_SALES"] = "INTERNAL_SALES";
    AccountingTreatment["OTHER_REVENUE"] = "OTHER_REVENUE";
    AccountingTreatment["FIXED_REVENUE"] = "FIXED_REVENUE";
    AccountingTreatment["STOCK_VALUE_INCREASE"] = "STOCK_VALUE_INCREASE";
    AccountingTreatment["OTHER_GAINS"] = "OTHER_GAINS";
    AccountingTreatment["COGS"] = "COGS";
    AccountingTreatment["OPEX"] = "OPEX";
    AccountingTreatment["CAPEX"] = "CAPEX";
    AccountingTreatment["COST_OF_SALES"] = "COST_OF_SALES";
    AccountingTreatment["VARIABLE_COSTS"] = "VARIABLE_COSTS";
    AccountingTreatment["OTHER_COGS"] = "OTHER_COGS";
    AccountingTreatment["PRODUCTION_VARIABLE_COSTS"] = "PRODUCTION_VARIABLE_COSTS";
    AccountingTreatment["MAINTENANCE_VARIABLE_COSTS"] = "MAINTENANCE_VARIABLE_COSTS";
    AccountingTreatment["COMMERCIAL_VARIABLE_COSTS"] = "COMMERCIAL_VARIABLE_COSTS";
    AccountingTreatment["OTHER_VARIABLE_COSTS"] = "OTHER_VARIABLE_COSTS";
    AccountingTreatment["OTHER_LOSSES"] = "OTHER_LOSSES";
    AccountingTreatment["GROSS_PROFIT_ADJUSTMENTS"] = "GROSS_PROFIT_ADJUSTMENTS";
    AccountingTreatment["EBITDA_ADJUSTMENTS"] = "EBITDA_ADJUSTMENTS";
    AccountingTreatment["DIRECT_OPEX"] = "DIRECT_OPEX";
    AccountingTreatment["INDIRECT_OPEX"] = "INDIRECT_OPEX";
    AccountingTreatment["DIRECT_PERSONNEL_COSTS"] = "DIRECT_PERSONNEL_COSTS";
    AccountingTreatment["DIRECT_MAINTENANCE_COSTS"] = "DIRECT_MAINTENANCE_COSTS";
    AccountingTreatment["DIRECT_GA_COSTS"] = "DIRECT_GA_COSTS";
    AccountingTreatment["DIRECT_OTHER_OPEX"] = "DIRECT_OTHER_OPEX";
    AccountingTreatment["INDIRECT_PERSONNEL_COSTS"] = "INDIRECT_PERSONNEL_COSTS";
    AccountingTreatment["INDIRECT_MAINTENANCE_COSTS"] = "INDIRECT_MAINTENANCE_COSTS";
    AccountingTreatment["INDIRECT_GA_COSTS"] = "INDIRECT_GA_COSTS";
    AccountingTreatment["INDIRECT_OTHER_OPEX"] = "INDIRECT_OTHER_OPEX";
    AccountingTreatment["STOCK_VALUE_DECREASE"] = "STOCK_VALUE_DECREASE";
    AccountingTreatment["PROVISIONS"] = "PROVISIONS";
    AccountingTreatment["BAD_DEBT"] = "BAD_DEBT";
    AccountingTreatment["ADJUSTMENTS"] = "ADJUSTMENTS";
    AccountingTreatment["OTHER_COSTS"] = "OTHER_COSTS";
    AccountingTreatment["OTHER_OTHER_COSTS"] = "OTHER_OTHER_COSTS";
    AccountingTreatment["AMORTIZATION"] = "AMORTIZATION";
    AccountingTreatment["DEPRECIATION"] = "DEPRECIATION";
    AccountingTreatment["TAX"] = "TAX";
    AccountingTreatment["FINANCIAL_RESULTS"] = "FINANCIAL_RESULTS";
    AccountingTreatment["PRODUCTION"] = "PRODUCTION";
    AccountingTreatment["CONSUMPTION"] = "CONSUMPTION";
    AccountingTreatment["EMPLOYEE"] = "EMPLOYEE";
    AccountingTreatment["CLIENT"] = "CLIENT";
    AccountingTreatment["STORE_AREA"] = "STORE_AREA";
    AccountingTreatment["OTHER"] = "OTHER";
})(AccountingTreatment || (exports.AccountingTreatment = AccountingTreatment = {}));
